<template>
    <div>
        <header-portrate />
        <exports-print ref="exportbar" />
        <div class="m-2" style="margin-bottom:70px !important;">
            <bread-crumbs :items="items"></bread-crumbs> 
            <tabs-comp :tabs="tabs" />
            <v-row class="mainrow m-2" :style="`direction:`+lang.dir+`;margin-top:20px !important;`">
                <v-col cols="2">
                    <label>{{lang.chose_month}}</label>
                    <b-input-group>
                    <b-form-select class="selborder"
                        v-model="month"
                        :options="months"
                        style="width:200px;"
                        @change="getEmployees()"
                    ></b-form-select>
                        <b-input-group-addon style="margin-right:-30px;margin-top:5px;">
                            <i class="fas fa-arrow-down"></i>
                        </b-input-group-addon>
                    </b-input-group>
                </v-col>
                <v-col cols="2">
                    <label>{{lang.year}}</label>
                    <b-form-input class="inborder"
                    v-model="year"
                    style="width:100px;"
                    @change="getEmployees()"
                    >{{ year }}</b-form-input>
                </v-col>
                <v-col cols="12">
                    <v-simple-table dense fixed-header>
                        <thead>
                            <th class="text-center backBlack">{{ lang.full_name }}</th>
                            <th class="text-center backBlack">{{ lang.mobile }}</th>
                            <th class="text-center backBlack">{{ lang.basic_salary }}</th>
                            <th class="text-center backBlack">{{ lang.home_allownance }}</th>
                            <th class="text-center backBlack">{{ lang.trans_allownance }}</th>
                            <th class="text-center backBlack">{{ lang.food_allown }}</th>
                            <th class="text-center backBlack">{{ lang.other_allown }}</th>
                            <th class="text-center backBlack">{{ lang.percentage }}</th>
                            <th class="text-center backBlack">{{ lang.total }}</th>
                        </thead>
                        <tbody>
                            <tr v-for="(item,index) in employees" :key="index">
                                <td class="text-center">{{item.employee_name}}</td>
                                <td class="text-center">{{item.mobile}}</td>
                                <td class="text-center">{{item.salary}}</td>
                                <td class="text-center">{{item.home_allown}}</td>
                                <td class="text-center">{{item.tarns_allown}}</td>
                                <td class="text-center">{{item.food_allown}}</td>
                                <td class="text-center">{{item.others_allown}}</td>
                                <td class="text-center">{{item.Perce}}</td>
                                <td class="text-center">{{item.stotal}}</td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </v-col>
            </v-row>
        </div>
        <Footer />
    </div>
</template>

<script>
import axios from 'axios';
import BreadCrumbs from '@/components/breadCrumbs.vue';
import TabsComp from '@/components/tabsComp.vue';
import HeaderPortrate from '@/components/Header-Portrate.vue';
import Footer from '@/components/Footer.vue';
import ExportsPrint from '@/components/exports-print.vue';
export default{
    components: {BreadCrumbs,TabsComp,HeaderPortrate,Footer,ExportsPrint},
    data() {
        return {
            employees:[],
            cardsrows: [],
            invoicerows: [],
            rewarded: [],
            payadvanced: [],
            deducts: [],
            custodies: [],
            salaryregisterd: [],
            endofservice: 0,
            months: [
                {text: "January",value:1},
                {text: "February",value:2},
                {text: "March",value:3},
                {text: "April",value:4},
                {text: "May",value:5},
                {text: "June",value:6},
                {text: "July",value:7},
                {text: "August",value:8},
                {text: "September",value:9},
                {text: "October",value:10},
                {text: "November",value:11},
                {text: "December",value:12},
            ],
            month: '',
            year: 2022,
            rsalary: [],
            ppsalary: [],
        }
    },
    computed:{
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        headers: function(){
            return [
                    {text: this.lang.full_name},
                    {text: this.lang.mobile},
                    {text: this.lang.basic_salary},
                    {text: this.lang.home_allownance},
                    {text: this.lang.trans_allownance},
                    {text: this.lang.food_allown},
                    {text: this.lang.other_allown},
                    {text: this.lang.percentage},
                    {text: this.lang.total},{}
                ]
        },
        tabs: function() {
            const menu = this.$store.getters['state'].menu;
            const license = this.$store.getters['state'].licenseType.activemenu;
            const alicense = this.$store.getters['state'].licenseType
            let currentMenu = menu[license];
            if(!license){
              return false;
            }
            let cMenu = {main: {},sub:[]};
            Object.values(currentMenu).forEach(value =>{
            const cM = localStorage.getItem('currentMenu');
            if(value.main.index == cM){
                cMenu = value.sub;
            }else if(cM == null && value.main.index == 1){
                cMenu = value.sub;
            }
            });
        
            let t = []
            Object.values(cMenu).forEach(value =>{
            
                if(this.$route.path == value.href){
                    value.class = "mytab";
                }else{
                    value.class = "mytab4";
                }
                if(this.lang.langname == 'ar'){
                    value.name = value.arname
                }else{
                    value.name = value.enname
                }
                for(let i =0; i< value.groupid.length;i++){
                    if(value.groupid[i] == this.$store.getters['state'].group_id){
                    if(alicense[value.perms[0]] || value.perms[0] == 'any')
                        t.push(value)
                    }
                }
            
            })
        
            return t;
        },
        items:function(){
            return [];
        }

    },
    methods:{
        preparedata(){
            let t = this.headers;
            let data = this.employees;
            let hdata = [];
            let exdata = [];
            for(let i=0;i<t.length;i++){
                hdata.push(t[i].text);
            }
            exdata.push(hdata);
            for(let k=0;k<data.length;k++){
            let x = [];

                x.push(data[k].employee_name); 
                x.push(data[k].mobile);
                x.push(data[k].salary);
                x.push(data[k].home_allown);
                x.push(data[k].tarns_allown);
                x.push(data[k].food_allown);
                x.push(data[k].others_allown);
                x.push(data[k].Perce);
                x.push(data[k].stotal);
                
                exdata.push(x);
            }
            this.$refs.exportbar.exportdata = exdata;
            this.$refs.exportbar.sdate = "01-" + this.month+"-"+this.year;

            this.$refs.exportbar.exporttitle = this.lang.salary_report;
  
        },
        getEmployees(){
            const post = new FormData();
            post.append("type" , "getAllEmpsTable");
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[month]',this.month);
            post.append('data[year]',this.year);
            
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                this.rsalary = [];
                // console.log("asdfasdf",response.data);
                const res = response.data.results.data;
                this.employees = res;
                // console.log("asdfasdf",res);
            })
        },
        getMonth(){
            let tm = new Date();
            this.month = parseFloat(tm.getMonth()) + parseFloat(1);
            this.year = tm.getFullYear()
        },
    },
    created() {
        this.getMonth();
        this.getEmployees();
    },
}
</script>